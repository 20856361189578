import request from './http.js'

export function post(url, data) {
    return request({
        method: 'POST',
        url,
        headers: {
            'Content-Type': 'application/json'
        },
        data
    })
}

// 登录
export function login(data) {
    return post('/login', data);
}
// 退出
export function exit(data) {
    return post('/logout', data);
}