import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/reset.less";
import "./style/custom.less";
import { Loading, Notification } from "element-ui";

Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(Notification);
Notification.closeAll();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
