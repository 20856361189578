import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout/Layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "登录"
    }
  },
  {
    path: "/pc",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "user-list",
        component: () => import("../views/UserList.vue"),
        meta: {
          title: "人员管理"
        }
      },
      {
        path: "office-card",
        component: () => import("../views/OfficeCard.vue"),
        meta: {
          title: "办公室&门牌管理"
        }
      },
      {
        path: "at-work",
        component: () => import("../views/AtWork.vue"),
        meta: {
          title: "人员在/离岗管理"
        }
      },
      {
        path: "metting-room",
        component: () => import("../views/MeetingRoom.vue"),
        meta: {
          title: "会议室&门牌管理"
        }
      },
      {
        path: "metting-reservation",
        component: () => import("../views/MettingReservation.vue"),
        meta: {
          title: "会议室预约记录"
        }
      },
      {
        path: "metting-sign",
        component: () => import("../views/MettingSign.vue"),
        meta: {
          title: "会议室签到记录"
        }
      },
      {
        path: "table-cards",
        component: () => import("../views/TableCards.vue"),
        meta: {
          title: "电子桌牌管理"
        }
      },
      {
        path: "files-cabinet",
        component: () => import("../views/FilesCabinet.vue"),
        meta: {
          title: "档案柜管理"
        }
      },
      {
        path: "cabinet-door",
        component: () => import("../views/CabinetDoor.vue"),
        meta: {
          title: "柜门管理"
        }
      },
      {
        path: "cabinet-Record",
        component: () => import("../views/CabinetRecord.vue"),
        meta: {
          title: "柜门记录"
        }
      },
      {
        path: "admin-set",
        component: () => import("../views/AdminSet.vue"),
        meta: {
          title: "管理员设置"
        }
      }
    ]
  },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "登录"
    }
  },
];

const router = new VueRouter({
  routes,
});

export default router;
