<template>
    <!-- 修改密码，退出 -->
    <div class="user-btns">
        <div class="name">{{name}}</div>
        <!-- <div class="edit-password">修改密码</div> -->
        <div class="exit" @click="handleExitClick">
            <el-image :src="require('../../assets/exit.png')">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
        </div>
    </div>
</template>
  
<script>
import Vue from "vue";
import { exit } from "@/services/services.js";
import { Notification, Image } from "element-ui";
Vue.use(Image);
export default {
    name: "Exit",
    data(){
        return {
            name:'',
        }
    },
    components: {
    },
    computed: {},
    methods: {
        async handleExitClick() {
            let data = await exit();
            let { code, msg } = data;
            if (code === 200) {
                this.$router.push({
                    path: '/login'
                });
            } else {
                Notification({
                    title: '失败',
                    message: msg,
                    duration: 3000,
                    type: 'error'
                });
            }
        }
    },
    mounted(){
        let userinfo = sessionStorage.getItem('userinfo');
        if(userinfo){
            userinfo = JSON.parse(userinfo);
        }
        this.name = userinfo.name;
    }
};
</script>
<style lang="less" scoped>
.user-btns {
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 32px;
}

.name {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-right: 20px;
}

.edit-password {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-right: 64px;
    position: relative;

    &::after {
        content: " ";
        display: block;
        width: 1px;
        height: 29px;
        background: #DCDFE6;
        position: absolute;
        right: -32px;
        top: -3px;
    }
}

.exit {
    width: 24px;
    height: 24px;
}
</style>
  