<template>
  <div class="sidebar-container">
    <div class="logo">
      <el-image class="logo-icon" :src="require('../../assets/pic_home_logo@3x.png')">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <el-menu class="el-menu-vertical-demo" @open="handleOpen" :default-active="defaultPage" @close="handleClose"
      text-color="#A5ABC0" active-text-color="#fff">
      <el-menu-item index="user-list">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_personnelmanagement@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="user-list">人员管理</router-link>
        </div>
      </el-menu-item>
      <el-menu-item index="office-card">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_Officedoorplate@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="office-card">办公室&门牌管理</router-link>
        </div>
      </el-menu-item>
      <el-menu-item index="at-work">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_onandoffthejob@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="at-work">人员在/离岗管理</router-link>
        </div>
      </el-menu-item>
      <el-menu-item index="metting-room">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_meetingroomdoor@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="metting-room">会议室&门牌管理</router-link>
        </div>
      </el-menu-item>
      <el-submenu index="6">
        <template slot="title">
          <div class="menu-link-btn">
            <el-image class="menu-icon" :src="require('../../assets/icon_records@3x.png')">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <span>会议室记录管理</span>
          </div>
        </template>
        <el-menu-item index="metting-reservation">
          <div class="menu-link-btn" slot="title">
            <router-link to="metting-reservation">会议室预约记录</router-link>
          </div>
        </el-menu-item>
        <el-menu-item index="metting-sign">
          <div class="menu-link-btn" slot="title">
            <router-link to="metting-sign">会议室签到记录</router-link>
          </div>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="table-cards">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_tablecard@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="table-cards">电子桌牌管理</router-link>
        </div>
      </el-menu-item>
      <el-submenu index="8">
        <template slot="title">
          <div class="menu-link-btn">
            <el-image class="menu-icon" :src="require('../../assets/icon_filingcabinet@3x.png')">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <span>档案柜管理</span>
          </div>
        </template>
        <el-menu-item index="files-cabinet">
          <div class="menu-link-btn" slot="title">
            <router-link to="files-cabinet">档案柜管理</router-link>
          </div>
        </el-menu-item>
        <el-menu-item index="cabinet-door">
          <div class="menu-link-btn" slot="title">
            <router-link to="cabinet-door">柜门管理</router-link>
          </div>
        </el-menu-item>
        <el-menu-item index="cabinet-Record">
          <div class="menu-link-btn" slot="title">
            <router-link to="cabinet-Record">柜门记录</router-link>
          </div>
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="admin-set">
        <div class="menu-link-btn" slot="title">
          <el-image class="menu-icon" :src="require('../../assets/icon_settings@3x.png')">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <router-link to="admin-set">管理员设置</router-link>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { Menu, Submenu, MenuItem, MenuItemGroup, Image } from "element-ui";

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Image);
export default {
  name: "Sidebar",
  data() {
    return {
      defaultPage: '',
    }
  },
  components: {},
  computed: {},
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {
    let path = this.$route.path;
    path = path.replace('/pc/', '');
    if (path !== '' && path !== 'login') {
      this.defaultPage = path;
    }
  }
};
</script>

<style lang="less">
.sidebar-container {
  width: 240px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #38405B;
  border-radius: 0px 12px 12px 0px;

  .el-menu {
    background: #38405B;
    border: none;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background: linear-gradient(270deg, #FF6767 0%, #FF7E65 100%) !important;
    color: #fff !important;
  }

  .el-submenu__title:focus,
  .el-submenu__title:hover {
    background: linear-gradient(270deg, #FF6767 0%, #FF7E65 100%) !important;
    color: #fff !important;
  }

  .el-submenu__title:hover {
    background: linear-gradient(270deg, #FF6767 0%, #FF7E65 100%) !important;
    color: #fff !important;
  }

  .el-menu-item,
  .el-submenu__title {
    height: 48px;
    line-height: 48px;
  }

  .el-dropdown-menu__item,
  .el-menu-item {
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .el-menu-item.is-active {
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
</style>
<style lang="less" scoped>
.logo {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #697494 0%, rgba(56, 64, 91, 0) 100%);
  border-radius: 0px 12px 0px 0px;
}

.logo-icon {
  width: 188px;
  height: 34px;
}

.menu-link-btn {
  display: flex;
  align-items: center;
  a,
  span {
    margin-left: 20px;
    color: inherit;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}

.menu-icon {
  width: 20px;
}
</style>
