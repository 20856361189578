<template>
  <div class="app-wrapper">
    <!-- 侧边栏 -->
    <Sidebar />
    <div class="main-container">
      <!-- 头部 -->
      <div class="tabbar">
        <!-- 修改密码，退出 -->
        <Exit />
      </div>
      <!-- 主窗口 -->
      <div class="app-main">
        <router-view></router-view>
      </div>
      <!-- 底部版权声明 -->
      <div class="foot-copyright-text flex aic jcc">
        余杭区居家无忧 Copyright © 2023-2023 Golive All Rights Reserved
        浙ICP备1888888号-4
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Exit from "./Exit.vue";
import Vue from "vue";
import { Image } from "element-ui";
Vue.use(Image);
export default {
  name: "Layout",
  components: {
    Sidebar,
    Exit,
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
}

.main-container {
  margin-left: 240px;
  min-width: 1040px;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.tabbar {
  height: 80px;
  flex-shrink: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.app-main {
  margin: 24px 24px 0 24px;
  padding: 20px;
  flex: 1;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 2px 0px 12px 0px rgba(74, 88, 146, 0.1);
  border-radius: 12px;
}

.foot-copyright-text {
  font-size: 14px;
  flex-shrink: 0;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A9A9B5;
  line-height: 20px;
  margin: 10px 0;
}

.user-btns {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 32px;
}

.name {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  margin-right: 20px;
}

.edit-password {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-right: 64px;
  position: relative;

  &::after {
    content: " ";
    display: block;
    width: 1px;
    height: 29px;
    background: #DCDFE6;
    position: absolute;
    right: -32px;
    top: -3px;
  }
}

.exit {
  width: 24px;
  height: 24px;
}
</style>
