import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import router from '@/router'

function redirectLogin() {
    router.push({
        name: 'login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    })
    window.localStorage.removeItem('token')
}

const request = axios.create({
    baseURL: process.env.VUE_APP_API
})

request.interceptors.request.use(function (config) {
    const token = store.state.token
    if (token) {
        config.headers.Authorization = token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

request.interceptors.response.use(function (response) { // 请求响应成功
    // let statusMap = {
    //     '200': '请求成功（登录成功/新增成功/修改成功/删除成功/操作成功/预约成功）',
    //     '300': '缺少必传参数',
    //     '301': '不支持的请求方法,仅支持POST请求',
    //     '302': '不支持的Content-type,必须是application/json格式',
    //     '340': '查询内容不存在，请刷新后重试',
    //     '341': '当前时间段该会议室已有其他会议，请选择其他时间段',
    //     '360': '第三方请求出错，请刷新后确认',
    //     '404': '请求地址不存在',
    //     '400': '服务器错误',
    //     '500': '登录超时，请重新登录',
    //     '501': '账号或密码错误',
    //     '502': '密码错误',
    // }
    if (Object.prototype.toString.call(response.data) !== '[object Blob]') {
        if (response.data.code === 200) {
            return response
        } else if (response.data.code === 500) { // 参数错误
            Message.error('登录超时，请重新登录')
            redirectLogin()
        } else {
            Message.error(response.data.msg || '错误')
        }

        return Promise.reject(response.data)
    }
    return response
}, async function (error) {
    if (error.response) {
        const { status } = error.response
        if (status === 400) {
            Message.error('请求参数错误')
        }else if (status === 403) {
            Message.error('没有权限，请联系管理员')
        } else if (status === 404) {
            Message.error('请求资源不存在')
        } else if (status >= 500) {
            redirectLogin()
        }
    } else if (error.request) {
        Message.error('请求超时，请刷新重试')
    } else {
        Message.error(`请求失败，${error.message}`)
    }
    return Promise.reject(error)
})

export default (params) => {
    return request(params).then(res => {
        return res.data;
    })
}
